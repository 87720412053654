<template>
  <div class="flex flex-wrap gap-3.5">
    <button
      v-for="provider in providers"
      :key="provider.id"
      type="button"
      class="inline-flex flex-[1_0_calc(33.333%-14px)] items-center justify-center gap-2 whitespace-nowrap rounded border border-[rgba(30,36,50,0.23)] px-5.5 py-2 text-base"
      @click="authByProviderId(provider.id)"
    >
      <UiIcon :name="provider.icon" class="size-6 flex-shrink-0" skip-svgo />
      <span>{{ $t("continueWith", { title: provider.name }) }}</span>
    </button>
  </div>
</template>

<script setup lang="ts">
import { signInWithPopup, OAuthProvider } from "firebase/auth";
import { SocialAuthParams } from "~/types/api/auth.types";
import { useGeneralStore } from "~/stores/general";

const emit = defineEmits(["success", "failed"]);

const providers = [
  { id: "google.com", name: "Google", icon: "social-logo/google" },
  { id: "facebook.com", name: "Facebook", icon: "social-logo/facebook" },
  // { id: "apple.com", name: "Apple", icon: "social-logo/apple" },
] as const;

type ProviderIds = (typeof providers)[number]["id"];

const { $api, $socialAuth } = useNuxtApp();
const { cartUniqueId } = useCart();
const { locale } = useI18n();

const generalStore = useGeneralStore();

function getProviderScopes(providerId: ProviderIds) {
  switch (providerId) {
    case "google.com":
      return ["profile", "email"];
    case "facebook.com":
      return ["public_profile", "email"];

    // case "apple.com":
    //   return ["name", "email"];

    default:
      return [];
  }
}

async function authByProviderId(providerId: ProviderIds) {
  if (!$socialAuth?.auth) return;
  const provider = new OAuthProvider(providerId);
  const scopes = getProviderScopes(providerId);
  scopes.forEach((scope) => {
    provider.addScope(scope);
  });

  provider.setCustomParameters({
    locale: locale.value,
  });

  try {
    const result = await signInWithPopup($socialAuth.auth, provider);

    if (result.user) {
      const params: SocialAuthParams = {
        token: result.user.accessToken,
      };
      if (cartUniqueId.value) {
        params.cart_unique_id = cartUniqueId.value;
      }

      const response = await $api.auth.socialAuth(params);

      generalStore.setUserInfo(response.data || null);
      generalStore.isSignInDrawerOpen = false;
      await Promise.all([generalStore.getCart(), generalStore.getWishList()]);
      emit("success");
    } else {
      emit("failed");
    }
  } catch (error) {
    emit("failed");
    console.error(error);
  }
}
</script>

<style>
.social-auth {
  display: flex;
  flex-wrap: wrap;
  gap: 14px;
}

.social-auth--button {
  display: inline-flex;
  padding: 8px 22px;
  align-items: center;
  justify-content: center;
  gap: 8px;
  border-radius: 4px;
  border: 1px solid rgba(30, 36, 50, 0.23);
  font-size: 16px;
  white-space: nowrap;
  flex: 1 0 calc(33.333% - 14px);
}

.social-auth--button-icon {
  width: 24px;
  height: 24px;
  flex-shrink: 0;
}
</style>
